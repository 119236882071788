<template>
  <div class="">
    <span> </span>
    <v-menu :close-on-content-click="false" v-model="menu">
      <template v-slot:activator="{ on }">
        <span v-on="on">
          <v-chip class="cursor-pointer" v-if="data.team" small color="success">
            {{ data.team }}
          </v-chip>
          <v-btn v-else color="primary" icon v-on="on"
            ><v-icon>{{ actionIcon }}</v-icon></v-btn
          >
        </span>
      </template>
      <v-card width="300">
        <v-container>
          <v-text-field
            v-model="parcel.team"
            label="Team"
            outlined
          ></v-text-field>

          <v-btn color="primary" @click="update()"
            >{{ data.team ? 'Update' : 'Save' }}
            <v-icon>mdi-content-save</v-icon></v-btn
          >

          <span @click="menu = false">
            <cancel-button></cancel-button>
          </span>
        </v-container>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import parcel from '@/mixins/parcel'
export default {
  mixins: [parcel],
  props: ['data'],
  computed: {
    actionIcon() {
      if (this.data.team) {
        return 'mdi-pencil'
      } else {
        return 'mdi-plus-circle'
      }
    }
  },
  data: () => ({
    menu: false,
    parcel: {}
  }),
  mounted() {
    if (this.data) {
      this.parcel = { ...this.data }
    }
  },
  methods: {
    async update() {
      let parcel = { ...this.parcel }
      let parcelId = parcel.id
      delete parcel.id
      await this.updateParcel(parcelId, parcel)
      this.menu = false
      this.updateMetrics({
        feature: 'parcel',
        action: 'update',
        parcel: parcelId,
        number: parcel.number
      })
    }
  }
}
</script>

<style></style>
